/***********************************************************************************
 ************************************************************************************
 *******************************HERO-Software-Template*******************************
 ************************************************************************************
 ************************************************************************************/

import * as React from "react"
import styled from "styled-components"
import { HEROSecondary } from "../../static/styles/colors"
import Image from "../common/heroImage"
import { FaArrowDown } from "@react-icons/all-files/Fa/FaArrowDown"
import { ButtonPrimaryHERO } from "../common/styledComponentsHelper"
import HEROLabel from "../../images/svg/hero-label.svg"

const EventHeroSection = ({ desktopImg, mobileImg, eventDate, eventTime }) => {
  return (
    <div className="container-fluid overflow-hidden">
      <div className="row bg-HERO-secondary fs-InterRegular flex-lg-row-reverse align-items-center">
        <div className="col-lg-7">
          <div className="container mb-lg-0 mb-5">
            <div className="display-4 fs-InterBold lh-1 mt-lg-0 mt-3 text-light">
              Schluss mit dem Geld drauflegen!
            </div>
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="mb-5">
                  <div className="fs-5 text-HERO-primary fs-InterMedium">
                    Mit 3 Zahlen zu mehr Gewinn!
                  </div>
                </div>
                <div className="text-HERO-primary pt-2 mb-0 ">
                  <div className="display-4 ps-3 ms-3 ">
                    {eventDate}
                    <div className="fs-5 lh-sm">
                      {eventTime}
                      <br />
                      90 min
                    </div>
                  </div>
                  <VerticallyContainer className="fs-5 text-light">
                    Save the date
                  </VerticallyContainer>
                </div>
                <p className="text-light fs-xl-5 text-light pt-xl-3">
                  <strong className="fw-bold">Fachhandwerk360</strong> bietet
                  Ihnen kostenlos ein unvergessliches Live Online Seminar zur
                  Bestimmung Ihrer Preisuntergrenze
                </p>
                <ButtonPrimaryHERO>
                  <a
                    href="#registration"
                    className="align-items-center fs-InterBold d-flex"
                  >
                    Jetzt kostenlos Anmelden
                    <FaArrowDown className="ms-3" color={HEROSecondary} />
                  </a>
                </ButtonPrimaryHERO>
              </div>
              <div className="col-md-4 col-12 pt-5">
                <div className="row flex-row-reverse">
                  <div className="col-md-12 col-6">
                    <div className="d-flex justify-content-end pb-xl-5">
                      {/* <HEROLabel className="w-75 pb-md-0 pb-5" /> */}
                    </div>
                  </div>
                  <div className="col-md-12 col-6">
                    <a
                      href="https://www.provenexpert.com/fachhandwerk360-dreier-herber-gbr/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
                      title="Kundenbewertungen &amp; Erfahrungen zu Fachhandwerk360 Dreier & Herber GbR. Mehr Infos anzeigen."
                      rel="noreferrer"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                      }}
                      className="d-md-flex justify-content-end mt-xl-5"
                    >
                      <img
                        src="https://images.provenexpert.com/18/64/b20854e92890645f836d7a4bb109/widget_portrait_180_de_0.png"
                        alt="Erfahrungen &amp; Bewertungen zu Fachhandwerk360 Dreier & Herber GbR"
                        style={{ border: "0", width: "60%", minWidth: "125px" }}
                        className="d-none d-md-block"
                      />
                      <img
                        src="https://images.provenexpert.com/18/64/b20854e92890645f836d7a4bb109/widget_portrait_180_de_0.png"
                        alt="Erfahrungen &amp; Bewertungen zu Fachhandwerk360 Dreier & Herber GbR"
                        style={{ border: "0", width: "157px" }}
                        className="d-md-none d-block"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 p-0">
          <Image
            alt="Hero Banner"
            filenameDesktop={desktopImg}
            filenameMobile={mobileImg}
          />
        </div>
      </div>
    </div>
  )
}

const VerticallyContainer = styled.div`
  padding-left: 3px;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -o-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  @media screen and (max-width: 1400px) {
    font-size: 1rem !important;
  }
`

export default EventHeroSection
