/***********************************************************************************
 ************************************************************************************
 *******************************HERO-Software-Template*******************************
 ************************************************************************************
 ************************************************************************************/

import React, { useState, useEffect } from "react"
import Layout from "../../../components/common/layout"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import EventHeroSection from "../../../components/online-seminar/eventHeroSection_HERO"

import HEROLogo from "../../../images/svg/hero-logo-subtitled.svg"
import EventContact from "../../../components/online-seminar/eventContact"
import { BsFileText } from "@react-icons/all-files/bs/BsFileText"
import { BiTask } from "@react-icons/all-files/bi/BiTask"
import { RiMoneyEuroBoxLine } from "@react-icons/all-files/ri/RiMoneyEuroBoxLine"
import { FaArrowDown } from "@react-icons/all-files/Fa/FaArrowDown"
import Video from "../../../components/common/video"
import ReviewContainer from "../../../components/review-container"
import {
  customerReview,
  customerVoicesHERO,
} from "../../../static/text/commonContent"

import {
  offerText,
  taskText,
  priceText,
  addedValueTextHERO,
  eventDescriptionText,
  rdDescription,
  mhDescription,
} from "../../../static/text/eventContent"
import { HEROPrimary, darkColor } from "../../../static/styles/colors"

const date = "23.03.2023"
const time = "17:00"

export const Head = () => (
  <>
    <title>
      Live Online Seminar Bierdeckelkalkulation {date} | Fachhandwerk360
    </title>
    <meta name="description" content="Alles was ein Handwerker braucht" />
    <meta
      name="keywords"
      content="Kalkulation, Beratung, Digitalisierung, Handwerk, Software, kaufmännischer
      Begleiter Bauhandwerk"
    />
  </>
)

const Event = () => {
  const [loaded, setLoaded] = useState(false)
  const propValues = {
    Vorname: "",
    Nachname: "",
    Email: "",
    PLZ: "",
    Telefonnummer: "",
    Gewerk: "",
    Datenschutz: false,
    HoneyPot: "",
    dataSendFrom: "eventSite",
    registrationLocation: "Newsletter",
    registrationDate: date,
    eventTime: time,
    coopPartner: "HERO-SOFTWARE",
    type: "Newsletter",
  }

  useEffect(() => {
    const scriptTag = document.createElement("script")

    scriptTag.src =
      "https://www.provenexpert.com/widget/landing_fachhandwerk360-dreier-herber-gbr.js?feedback=0&avatar=0&competence=0&style=black"
    scriptTag.addEventListener("load", () => setLoaded(true))
    document.body.appendChild(scriptTag)
  }, [])

  useEffect(() => {
    if (!loaded) return
  }, [loaded])

  if (process.env.EVENT_DEVELOP) {
    return (
      <Layout>
        <EventHeroSection
          desktopImg="event_banner_hero_desktop.png"
          mobileImg="event_banner_hero_mobile.png"
          eventDate={date}
          eventTime={`${time} Uhr`}
        />
        <Separator />
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <h2 className="fs-InternBold text-start">
                Bierdeckelkalkulation für das Bauhandwerk
              </h2>
              <br />
              <div className="text-start fs-InterMedium">
                {eventDescriptionText}
                <br />
                <SignUpButton
                  className="btn btn-HERO-primary fs-InterBold px-5 py-2 text-HERO-secondary rounded-pill mb-3"
                  href="#registration"
                >
                  Jetzt kostenlos Anmelden{" "}
                  <FaArrowDown className="ms-1 mb-2" color={darkColor} />
                </SignUpButton>
              </div>
            </div>
            <div className="col-md-4">
              {" "}
              <StaticImage
                src="../../../images/event/hero-software/image-section.jpg"
                layout="fullWidth"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Martin Herber Vortrag"
                placeholder="none"
                className="rounded shadow"
              />
            </div>
          </div>
        </div>
        <Separator />
        <div className="bg-HERO-white bg-opacity-25 mt-5 pt-5">
          <Separator />
          <h2 className="fs-InterBold text-center">Inhalte und Ziele</h2>
          <div className="container">
            <div className="row justify-content-between p-3">
              <div className="col-lg-12 fs-InterMedium text-center p-4 fs-4">
                Ihr Handwerksbetrieb kann mehr! <br />
                Sie verstehen Ihr Handwerk – und wir unseres! Wir helfen Ihnen
                beim:
              </div>
              <div className="col-lg-4 fs-InterMedium text-center p-4 mb-4">
                <div className="bg-light rounded shadow p-4 h-100">
                  <BsFileText size="3rem" />
                  <h3>Angebote erstellen</h3>
                  <p>{offerText}</p>
                </div>
              </div>
              <div className="col-lg-4 fs-InterMedium text-center p-4 mb-4">
                <div className="bg-light rounded shadow p-4 h-100">
                  <BiTask size="3rem" />
                  <h3>Aufgaben vereinfachen</h3>
                  <p>{taskText}</p>
                </div>
              </div>
              <div className="col-lg-4 fs-InterMedium text-center p-4 mb-4">
                <div className="bg-light rounded shadow p-4 h-100">
                  <RiMoneyEuroBoxLine size="3rem" />
                  <h3>Preise kalkulieren</h3>
                  <p>{priceText}</p>
                </div>
              </div>
              <div className="col-lg-12 text-center p-sm-4">
                <SignUpButton
                  className="btn btn-HERO-primary fs-InterBold px-5 py-2 text-HERO-secondary rounded-pill mb-3"
                  href="#registration"
                >
                  Jetzt kostenlos Anmelden{" "}
                  <FaArrowDown className="ms-1 mb-2" color={darkColor} />
                </SignUpButton>
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <div className="bg-HERO-secondary">
          <Separator />
          <div className="container p-sm-5 p-3 w-75">
            <div className="row align-items-center">
              <div className="col-lg-6 p-3 pe-0 ms-auto d-block">
                <StaticImage
                  src="../../../images/event/hero-software/bierdeckel.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Bierdeckel"
                  placeholder="none"
                  layout="fullWidth"
                  className="mx-auto"
                  style={{ maxWidth: "500px" }}
                />
              </div>
              <div className="col-lg-6">
                <table className="h-100">
                  <tbody>
                    <tr>
                      <td className="pt-3 pb-3 text-sm-start align-middle text-start">
                        <h2 className="fs-InterBold text-light">Mehrwert:</h2>
                        <div className="text-light fs-InterMedium mt-4">
                          {addedValueTextHERO}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <SeparatorColorFull />
          <ReviewContainer isHERO="true" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-12">
                <div id="pewl" style={{ maxWidth: "100%" }} />
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <div className="container-fluid p-0 position-relative">
          <StaticImage
            src="../../../images/event/die_profis.png"
            layout="fullWidth"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Profis"
            placeholder="none"
          />
          <CenterText className="text-center text-light fs-InterBold">
            <h2>
              Wenn's drauf ankommt, lieber mal die Spezialisten machen lassen!
            </h2>
            <SignUpButton
              className="btn btn-HERO-primary fs-InterBold w-50 mx-auto px-5 py-2 text-HERO-secondary d-lg-block d-none rounded-pill mb-3"
              href="#registration"
            >
              Jetzt kostenlos Anmelden{" "}
              <FaArrowDown className="ms-1 mb-2" color={darkColor} />
            </SignUpButton>
          </CenterText>
        </div>
        <div className="bg-HERO-secondary">
          <Separator />
          <SignUpButton
            className="btn btn-HERO-primary px-5 py-2 fs-InterBold mb-3 mt-3 w-75 mx-auto d-lg-none d-block rounded-pill"
            href="#registration"
          >
            Jetzt kostenlos Anmelden{" "}
            <FaArrowDown className="ms-1 mb-2" color={darkColor} />
          </SignUpButton>
          <Separator />
          <div className="container">
            <div className="container">
              <h2 className="text-light text-uppercase text-bold text-center fw-bold">
                Teilnehmerstimmen{" "}
                <span className="text-HERO-primary">(Auszug)</span>
              </h2>
              <br />
              {customerVoicesHERO}
            </div>
          </div>
          <SeparatorColorFull />
        </div>
        <Separator />
        <div className="container">
          <h2 className="fs-InterBold text-center">Über die Fachhandwerk360</h2>
          <Separator />
          <div className="row">
            <div className="col-md-6">
              <div className="row justify-content-center">
                <p className="fs-5 fs-InterBold text-center">
                  Rainer Dreier
                  <br /> Gründer und Gesellschafter
                </p>
                <StaticImage
                  src="../../../images/event/rd_profile.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Rainer Dreier"
                  placeholder="none"
                  className="w-50"
                />
                <div className="text-center mt-3 d-flex justify-content-center fs-5">
                  <p className="w-50 fs-InterMedium">{rdDescription}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-center">
                <p className="fs-5 fs-InterBold text-center">
                  Martin Herber
                  <br /> Gründer und Gesellschafter
                </p>
                <StaticImage
                  src="../../../images/event/mh_profile.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Martin Herber"
                  placeholder="none"
                  className="w-50"
                />
                <div className="text-center mt-3 d-flex justify-content-center fs-5">
                  <p className="w-50 fs-InterMedium">{mhDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Separator />
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <SignUpButton
                className="btn btn-HERO-primary fs-InterBold px-5 py-2 text-HERO-secondary rounded-pill mb-3"
                href="#registration"
              >
                Jetzt kostenlos Anmelden{" "}
                <FaArrowDown className="ms-1 mb-2" color={darkColor} />
              </SignUpButton>
            </div>
          </div>
        </div>
        <Separator />
        <div className="bg-HERO-secondary">
          <SeparatorExtended />
          <div className="container">
            <div className="row">
              <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
                <Videocontainer>
                  <Video
                    videoSrcURL="https://www.youtube-nocookie.com/embed/KzZwXMKM3zo?rel=0"
                    videoTitle="Die Bierdeckelkalkulation - Das sagen unsere Handwerkskunden!"
                    videoStyle={{
                      top: "0",
                      left: "0",
                      height: "100%",
                    }}
                  />
                </Videocontainer>
              </div>
              <div className="col-xl-6 ps-sm-5 ps-3 pe-sm-5 pe-3">
                <table className="h-100 w-100">
                  <tbody>
                    <tr>
                      <td className="pt-3 pb-3 align-middle text-start text-light fs-5">
                        <h2 className="fs-InterBold">
                          Das sagen unsere{" "}
                          <span className="text-HERO-primary">Kunden</span>
                        </h2>
                        <div className="text-light fs-InterMedium mt-4 fs-5">
                          {customerReview}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <SeparatorExtended id="registration" />
          <h2 className="fs-InterBold text-light text-center display-5">
            Jetzt zum Live Online Seminar{" "}
            <span className="text-HERO-primary">Anmelden</span>
          </h2>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <EventContact initialPropValues={propValues} isHERO={true} />
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <div className="bg-HERO-primary">
          <Separator />
          <div className="container mt-5">
            <div className="d-flex justify-content-center">
              <HEROLogo className="mx-auto m-3" style={{ width: "175px" }} />
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6 col-12 pe-lg-5">
                <h2 className="text-HERO-black fs-InterBold fw-bold display-5">
                  Dieses Seminar wird präsentiert von HERO Software
                </h2>
                <p className="fs-InterMedium fs-5">
                  Weniger Papierkram, mehr Handwerk: HERO ist die
                  Handwerkersoftware Nr.1 um Ihr Handwerk zu entfesseln.
                </p>
                <SignUpButton
                  className="btn btn-HERO-secondary text-light fs-6 fs-InterBold px-5 py-2 rounded-pill mb-3"
                  href="https://hero-software.de/signup"
                  target="_blank"
                >
                  Jetzt kostenlos testen
                </SignUpButton>
              </div>
              <div className="col-lg-6 col-12 ps-lg-5 pt-lg-0 pt-5">
                <StaticImage
                  src="../../../images/event/hero-software/devices.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="HERO-Software-Logo"
                  placeholder="none"
                  className="mx-auto m-3"
                />
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <Separator />
        <h2 className="fs-InterBold text-center">Weitere Referenzen</h2>
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-4">
              <StaticImage
                src="../../../images/event/logo-HERO.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Meisterteam-Logo"
                placeholder="none"
                className="mx-auto m-3"
                style={{ maxWidth: "500px" }}
              />
              <StaticImage
                src="../../../images/event/logo-topaTeam.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="TopaTeam-Logo"
                placeholder="none"
                className="mx-auto m-3"
                style={{ maxWidth: "500px" }}
              />
            </div>
            <div className="col-lg-4">
              <StaticImage
                src="../../../images/event/fhw360/logo-schmidt-rudersdorf.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Schmidt-Rudersdorf Logo"
                placeholder="none"
                className="mx-auto m-3"
                style={{ maxWidth: "500px" }}
              />
              <StaticImage
                src="../../../images/event/logo-kreishandwerkerschaften_bundesweit.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Kreishandwerkerschaften_bundesweit-Logo"
                placeholder="none"
                className="mx-auto m-3"
                style={{ maxWidth: "500px" }}
              />
            </div>
            <div className="col-lg-4">
              <StaticImage
                src="../../../images/event/logo-emasters.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="emasters-Logo"
                placeholder="none"
                className="mx-auto m-3"
                style={{ maxWidth: "500px" }}
              />
              <StaticImage
                src="../../../images/event/logo-handwerkskammer_frankfurt.png"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Handwerkskammer_frankfurt-Logo"
                placeholder="none"
                className="mx-auto m-3"
                style={{ maxWidth: "500px" }}
              />
            </div>
          </div>
        </div>
        <Separator />
      </Layout>
    )
  }
}

const Separator = styled.hr`
  border: 5px solid transparent;
  padding: 2% 0px 2% 0px;
  margin: 0px;
  opacity: 0;
`
const SeparatorExtended = styled.hr`
  border: 5px solid transparent;
  padding: 5% 0px 5% 0px;
  margin: 0px;
  opacity: 0;
`

const SeparatorColorFull = styled.hr`
  border: 1px solid ${HEROPrimary};
  border-radius: 100px;
  width: 50%;
  margin: 4% auto;
  opacity: 1;
`
const CenterText = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  @media screen and (max-width: 768px) {
    font-size: 25px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`

const SignUpButton = styled.a`
  font-size: 1.5rem !important;
  @media screen and (max-width: 1200px) {
    font-size: 1.2rem !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

const Videocontainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`

export default Event
